import { Character_Side } from '@shared/types';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '@web/api/api-client';
import { Character } from '@web/components/player/ui/Character';
import { useThumbnail } from '../maker/providers/ThumbnailProvider';
import { useCharacterExtraStyles } from '../player/hooks/useCharacterExtraStyles';
import { SceneCharacter } from '../player/hooks/useCharacters';
import { useFlippedBasic } from '../player/hooks/useFlipped';
import { useFrameFilter } from '../player/hooks/useFrameFilter';

export const ThumbnailCharacters = () => {
  const { characters } = useThumbnail();

  if (!characters) {
    return null;
  }

  return characters.map((sceneCharacter, index) =>
    sceneCharacter ? (
      <ThumbnailCharacter key={index} sceneCharacter={sceneCharacter} />
    ) : null,
  );
};

const ThumbnailCharacter = ({
  sceneCharacter,
}: {
  sceneCharacter: NonNullable<SceneCharacter>;
}) => {
  const { serverThumbnails, flipTargets, effect } = useThumbnail();
  const { character, pose, target, pairedCharacter } = sceneCharacter;

  const filter = useFrameFilter(effect, target);
  const flipped = useFlippedBasic(flipTargets, target);
  const isCustomCharacter =
    character?.isPreset === undefined ? true : !character.isPreset;

  const extraStyles = useCharacterExtraStyles(character);

  const getThumbnail = async () => {
    if (!pose?.id) return;

    const response = await ApiClient.thumbnail.getPoseThumbnail(
      pose.id,
      isCustomCharacter,
    );

    return `${import.meta.env.VITE_BACKEND_BASE_URL}${response.data.url}`;
  };

  const { data: imageUrl, isLoading } = useQuery({
    queryKey: ['thumbnail', 'pose', pose?.id || 0, isCustomCharacter],
    queryFn: getThumbnail,
    staleTime: 30 * 60 * 1000,
    enabled: !!pose && serverThumbnails,
  });

  if (!character || !pose || isLoading) {
    return null;
  }

  const url = !serverThumbnails
    ? pose.idleImageUrl
    : imageUrl || pose.idleImageUrl;

  const isSpeedlines = !!pose?.isSpeedlines;
  const offsetX =
    character.offsetX + (!isSpeedlines ? (pairedCharacter?.offsetX ?? 0) : 0);
  const offsetY =
    character.offsetY + (!isSpeedlines ? (pairedCharacter?.offsetY ?? 0) : 0);

  return (
    <Character
      url={url}
      alignment={character.alignment}
      side={character.side as Character_Side}
      offsetX={offsetX}
      offsetY={offsetY}
      flipped={flipped}
      limitWidth={character.limitWidth}
      front={pairedCharacter?.front}
      style={{ ...filter, ...extraStyles }}
    />
  );
};
